@use "../../Global" as *;

.c-container {
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    
    &--s {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
}
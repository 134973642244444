/*=====
変数
=====*/

@use "sass:math";

// 色
$color-black: #333;
$color-black2: #4E5252;
$color-white: #ffffff;
$color-main: red;
$color-blue: #235AAB;
$color-blue2: #387EE5;
$color-darkblue: #071E40;
$color-skyblue: #46B3ED;
$color-yellow: #F6FE54;
$color-green1: #20D51D;
$color-green2: #04C300;
$color-orange1: #DD6C40;
$color-orange2: #E29B0F;
$color-tag: #FFF2D2;
$color-use: #4C6B9A;
$color-worries: #F0F8FC;
$color-risk: #253C51;
$color-reasonbg: #DDEFF8;

// フォント
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
$font-main: "Noto Sans JP", sans-serif;
$font-en: "Montserrat", sans-serif;
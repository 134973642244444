/*=====
Mixin
=====*/

@use "sass:math";

$breakpoints: (
  'sm': '(min-width: 520px)',
  'md': '(min-width: 768px)',
  'lg': '(min-width: 960px)',
  'xl': '(min-width: 1280px)',
  'xxl': '(min-width: 1920px)'
);

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@use "../../Global" as *;

.p-section {

    &__title {
        text-align: center;
        color: $color-blue;
        font-size: 3.2rem;
        line-height: 1.8;
        padding-bottom: 15px;
        margin-bottom: 30px;
        position: relative;

        &::after {
            content: '';
            width: 30px;
            height: 3px;
            background-color: $color-blue;
            position: absolute;
            left: 50%;
            bottom: 12px;
            transform: translateX(-50%);

            @include mq(lg) {
                bottom: 6px;
            }
        }
    }
}
@use "../../Global" as *;

.p-improvement {
    display: block;
    position: relative;
    padding-top: 65px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 56px;

    @include mq(lg) {
        padding-top: 60px;
        padding-left: 0;
        padding-right: 0;
    }
    & .arrow {
        position: absolute;
        left: 50%;
        top: calc(-65px/2);
        transform: translateX(-50%);
        z-index: 2;
    }
    &__inner {
        max-width: 840px;
        padding-top: 20px;
        padding-bottom: 20px;
        border: 3px solid $color-blue2;
        border-radius: 20px;
        box-shadow: 0 5px 30px rgba(8, 29, 62, 0.16);
        
        @include mq(lg) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    &__text {
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 2;
        letter-spacing: 0.05em;

        @include mq(lg) {
            font-size: 3.2rem;
        }
        & .blue {
            text-decoration: underline;
            text-underline-offset: 5px;
        }
    }
}
@use "../../Global" as *;

.c-title {
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    padding: 5px 10px;
    background-color: $color-blue;
    color: $color-white;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.05em;

    @include mq(lg) {
        width: fit-content;
        margin: 0 auto;
        font-size: 5rem;
        line-height: 1;
        border-radius: 5px;
        padding: 0 10px;
    }
    &__point {
        font-size: 4.2rem;
        color: $color-yellow;
        line-height: 1.2;

        @include mq(lg) {
            font-size: 6rem;
        }
    }
}
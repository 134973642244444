@use "../../Global" as *;

.p-faq {
    margin-bottom: 66px;

    @include mq(lg) {
        margin-bottom: 100px;
    }
    &__list {
        display: block;
        border-top: 1px solid $color-black;
        padding-top: 20px;
        padding-bottom: 30px;
        overflow: hidden;

        &.open {

            & .p-faq__question {

                &::after {
                    transform: translateY(-50%) rotate(90deg);
                }
            }
            & .p-faq__answer {
                line-height: 1.5;
                opacity: 1;
                visibility: visible;
                padding-top: 20px;

                @include mq(lg) {
                    padding-top: 40px;
                }
            }
        }
        &:last-child {
            border-bottom: 1px solid $color-black;
        }
    }
    &__question {
        display: grid;
        align-items: center;
        grid-template-columns: 40px 1fr;
        gap: 20px;
        padding-right: 50px;
        font-size: 2rem;
        font-weight: bold;
        cursor: pointer;
        position: relative;

        @include mq(lg) {
            font-size: 2.4rem;
            grid-template-columns: 50px 1fr;
            gap: 40px;

            & svg {
                width: 50px;
            }
        }
        &::before, &::after {
            content: '';
            background-color: $color-blue;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        &::before {
            width: 30px;
            height: 2px;
            right: 0;
        }
        &::after {
            width: 2px;
            height: 30px;
            right: 14px;
            transform-origin: center;
            transition: transform 0.5s;
        }
    }
    &__answer {
        padding-left: 60px;
        padding-right: 30px;
        line-height: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        transition: all 0.5s;

        @include mq(lg) {
            font-size: 2rem;
        }
        @include mq(xl) {
            font-size: 2rem;
            padding-left: 100px;
            padding-right: 90px;
        }
    }
}
@use "../../Global" as *;

.p-try {
    padding-top: 36px;
    position: relative;
    
    @include mq(lg) {
        padding-top: 52px;
    }
    &::before {
        content: '';
        width: 100%;
        height: 84%;
        background-image: url(../images/try/try_bg_sp.webp);
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;

        @include mq(lg) {
            height: 90%;
            background-image: url(../images/try/try_bg_pc.webp);
        }
    }
    &__subtitle {
        font-weight: bold;
        margin-bottom: 10px;

        @include mq(lg) {
            margin-bottom: 10px;
        }
        &.m {
            font-size: 2.8rem;

            @include mq(lg) {
                font-size: 3.2rem;
            }
        }
        &.l {
            font-size: 4.8rem;
            margin-bottom: 40px;

            @include mq(lg) {
                font-size: 6rem;
            }
        }
    }
    &__title {

        @include mq(lg) {
            margin-bottom: 10px;
        }
    }
    &__cta {

        @include mq(lg) {
            position: relative;

            &::before {
                content: '';
                width: 142px;
                height: 148px;
                background-image: url(../images/try/try.png);
                background-size: contain;
                position: absolute;
                top: -148px;
                right: 71px;
            }
        }
    }
}
@use "../../Global" as *;

.p-fixed {
    width: 100%;
    padding: 25px 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    background-color: #E2F5FF;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;

    &[data-js-scroll="true"] {
        opacity: 1;
        visibility: visible;
    }
    @include mq(lg) {
        display: none;
    }
    &__button {
        display: block;
        width: 100%;
        height: 60px;
        font-size: 2rem;
        font-weight: bold;
        color: $color-white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        box-shadow: 0 0 20px rgba(16, 39, 30, 0.22);
        position: relative;
    
        &::before {
            content: '';
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            border: 1px solid $color-white;
            border-radius: 5px;
            position: absolute;
            left: 2px;
            top: 2px;
        }
        &.btn-green {
            position: relative;
    
            & .free {
                width: 27px;
                // position: absolute;
                // left: 15px;
                // top: 50%;
                // transform: translateY(-50%);
                & path {
                    transition: fill 0.5s;
                }
            }
            // &:hover {
            //     background: $color-white;
            //     color: $color-green2;
    
            //     &::before {
            //         border-color: $color-green2;
            //     }
            //     & .free {
    
            //         & path {
            //             fill: $color-white;
    
            //             &.fill {
            //                 fill: $color-green2;
            //             }
            //         }
            //     }
            //     & .arrow {
    
            //         & circle {
            //             fill: $color-green2;
            //         }
            //         & path {
            //             fill: $color-white;
            //         }
            //     }
            // }
        }
        // &.btn-orange {
    
        //     &:hover {
        //         background: $color-white;
        //         color: $color-orange1;
    
        //         &::before {
        //             border-color: $color-orange1;
        //         }
        //         & svg {
    
        //             & circle {
        //                 fill: $color-orange1;
        //             }
        //             & path {
        //                 fill: $color-white;
        //             }
        //         }
        //     }
        // }
        & .s {
            font-size: 1.8rem;
        }
    }
}
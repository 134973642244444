@use "../../Global" as *;

.p-relief {
    display: block;
    padding-top: 240px;
    padding-bottom: 40px;
    margin-bottom: 60px;
    background-image: url(../images/relief/relief_bg_sp.webp);
    background-size: cover;

    @include mq(lg) {
        padding-top: 196px;
        padding-bottom: 35px;
        // padding-bottom: 57px;
        margin-bottom: 64px;
        background-image: url(../images/relief/relief_bg_pc.webp);
    }
    &__title {
        margin-bottom: 40px;

        @include mq(lg) {
            width: 100%;
            margin-bottom: 48px;
        }
    }
    &__grid {
        display: grid;
        gap: 20px;

        @include mq(lg) {
            grid-template-columns: repeat(2, 1fr);
            gap: 0;
            padding-top: 24px;
        }
    }
    &__text {
        font-size: 2rem;
        font-weight: bold;
        line-height: 2;
        letter-spacing: 0.05em;

        @include mq(lg) {
            font-size: 2.4rem;
            padding-left: 33px;
        }
        @include mq(xl) {
            font-size: 2.8rem;
        }
    }
}
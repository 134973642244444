@use "../../Global" as *;

.p-reason {
    padding-bottom: 40px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;

    @include mq(lg) {
        margin-bottom: 80px;
    }
    &__header {
        margin-bottom: 6px;

        @include mq(lg) {
            margin-bottom: 30px;
        }
    }
    &__balloon {
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1.65;
        letter-spacing: 0.05em;
        color: $color-white;
        background-color: $color-skyblue;
        border-radius: 5px;
        padding-top: 4px;
        padding-bottom: 6px;
        margin-bottom: 4px;
        box-shadow: 0 5px 30px rgba(8, 29, 62, 0.16);
        position: relative;

        @include mq(lg) {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: -3px;
        }
        &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
            border-top: 22px solid $color-skyblue;
            border-bottom: 0;
            position: absolute;
            left: 50%;
            bottom: -20px;
            transform: translateX(-50%);
        }
        & .s {
            font-size: 2.8rem;
        }
        & .dot {
            display: inline-block;
            position: relative;

            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $color-yellow;
                position: absolute;
                top: 1px;
                left: 50%;
                transform: translateX(-50%);
                
                @include mq(lg) {
                    top: -3px;
                }
            }
        }
    }
    &__title {
        font-size: 3.6rem;
        letter-spacing: 0.04em;

        & .m {
            font-family: $font-en;
            font-size: 5.6rem;
        }
    }
    &__grid {
        display: grid;
        gap: 30px;

        @include mq(lg) {
            gap: 100px;
        }
    }
    &__item {

        @include mq(lg) {
            display: flex;
            justify-content: center;
            position: relative;

            &::before {
                content: '';
                // width: 45%;
                width: calc(50vw - 224px);
                height: 95%;
                background-color: $color-reasonbg;
                position: absolute;
                bottom: -40px;
                z-index: -1;
            }
        }
        &:nth-child(2n - 1) {

            @include mq(lg) {

                &::before {
                    border-radius: 0 20px 20px 0;
                    left: calc(50% - 50vw);
                }
            }
            & .p-reason__item-media {

                @include mq(lg) {
                    transform: translateX(-56px);
                }
                &::before {
                    border-radius: 0 20px 20px 0;
                    left: calc(50% - 50vw);
                }
            }
        }
        &:nth-child(2n) {

            @include mq(lg) {
                flex-direction: row-reverse;

                &::before {
                    border-radius: 20px 0 0 20px;
                    right: calc(50% - 50vw);
                }
            }
            & .p-reason__item-media {

                @include mq(lg) {
                    transform: translateX(56px);
                }
                &::before {
                    border-radius: 20px 0 0 20px;
                    right: calc(50% - 50vw);
                }
            }
        }
        &-media {
            margin-bottom: 36px;
            position: relative;

            @include mq(lg) {
                width: 50%;
                margin-bottom: 0;
            }
            &::before {
                content: '';
                width: calc(100% - 5px);
                height: calc(100% - 10px);
                background-color: $color-reasonbg;
                position: absolute;
                bottom: -16px;
                z-index: -1;

                @include mq(lg) {
                    display: none;
                }
            }
            & img {
                aspect-ratio: 345/240;
                object-fit: cover;
                border-radius: 20px;
                
                @include mq(lg) {
                    aspect-ratio: 535/290;
                }
            }
        }
        &-contents {

            @include mq(lg) {
                width: 50%;
                padding-top: 30px;
                padding-right: 40px;
            }
        }
        &-label {
            display: block;
            width: fit-content;
            font-family: $font-en;
            font-weight: bold;
            letter-spacing: 0.125em;
            background-color: $color-skyblue;
            color: $color-white;
            padding: 5px 15px;
            border-radius: 30px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 22px;

            @include mq(lg) {
                margin-left: 0;
                margin-bottom: 10px;
            }
        }
        &-title {
            font-size: 2.4rem;
            letter-spacing: 0.05em;
            color: $color-blue;
            margin-bottom: 20px;
            
            @include mq(lg) {
                font-size: 3rem;
                margin-bottom: 12px;
            }
            @include mq(xl) {
                font-size: 3.6rem;
            }
        }
        &-caption {
            font-size: 1.6rem;
            line-height: 2;
            letter-spacing: 0.05em;
            color: $color-black2;

            @include mq(lg) {
                font-size: 2rem;
                line-height: 1.5;
            }
        }
    }
}
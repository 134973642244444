@use "../../Global" as *;

.p-worries {
    display: block;
    padding-top: 32px;
    padding-bottom: 60px;
    background-color: $color-worries;

    @include mq(lg) {
        padding-top: 50px;
    }
    &__title {
        font-size: 2.2rem;
        line-height: 1.6;
        letter-spacing: 0.1em;
        margin-bottom: 30px;
        
        @include mq(lg) {
            font-size: 3.2rem;
            margin-bottom: 28px;
        }
        & .blue {
            font-size: 3.6rem;
            text-decoration: underline;
            text-underline-offset: 5px;
        }
        & .dot {
            display: inline-block;
            position: relative;

            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $color-blue;
                position: absolute;
                top: -1px;
                left: 50%;
                transform: translateX(-50%);
                
                @include mq(lg) {
                    top: -3px;
                }
            }
        }
    }
    &__grid {
        display: grid;
        gap: 20px;

        @include mq(lg) {
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
        }
    }
    &__text {
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        color: $color-darkblue;
        padding-top: 36px;
        padding-bottom: 45px;
        background-color: $color-white;
        border: 2px solid $color-darkblue;
        border-radius: 20px;

        @include mq(lg) {
            padding-top: 35px;
        }
    }
    &__media {
        width: 160px;
        margin-top: -30px;

        @include mq(lg) {

            &.x10 {
                transform: translateX(10px);
            }
        }
    }
}
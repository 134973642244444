@use "../../Global" as *;

.p-case {
    display: block;
    margin-bottom: 60px;

    @include mq(lg) {
        margin-bottom: 100px;
    }
    &__title {

        &::after {
            bottom: 13px;
        }
    }
    &__balloon {
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.05em;
        color: $color-white;
        background-color: $color-skyblue;
        border-radius: 5px;
        padding-top: 5px;
        padding-bottom: 6px;
        margin-bottom: 22px;
        box-shadow: 0 5px 30px rgba(8, 29, 62, 0.16);
        position: relative;

        @include mq(lg) {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            padding: 12px 20px;
            margin-bottom: 28px;
        }
        &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
            border-top: 22px solid $color-skyblue;
            border-bottom: 0;
            position: absolute;
            left: 50%;
            bottom: -20px;
            transform: translateX(-50%);
        }
        & .s {
            font-size: 2.8rem;
        }
    }
    &__grid {
        display: grid;
        gap: 30px;
        margin-bottom: 60px;

        @include mq(lg) {
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: 150px;
        }
    }
    &__card {
        border-radius: 20px;
        box-shadow: 0 5px 30px rgba(#081D3E, 0.12);
        overflow: hidden;

        &-link {
            display: block;
            height: 100%;
                 
            &:hover {

                & .p-case__card-media {

                    & img {
                        transform: scale(1.05);
                    }
                }
                & .p-case__card-button {
                    background-color: $color-white;
                    color: $color-blue;
    
                    & svg {
    
                        & ellipse {
                            fill: $color-blue;
    
                        }
                        & path {
                            fill: $color-white;
                        }
                    }
                }
            }
        }
        &-inner {
            
            @include mq(lg) {
                // height: 100%;
                display: flex;
                flex-direction: column;
            }
        }
        &-media {
            position: relative;
            overflow: hidden;

            & img {
                aspect-ratio: 345/220;
                object-fit: cover;
                transition: transform 0.5s;

                @include mq(lg) {
                    aspect-ratio: 340/225;
                }
            }
        }
        &-contents {
            padding: 20px 20px 30px;

            @include mq(lg) {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 24px 20px 30px;
            }
        }
        &-title {
            font-size: 2rem;
            color: $color-blue;
            margin-bottom: 20px;
        }
        &-caption {
            font-size: 1.6rem;
            margin-bottom: 20px;

            @include mq(lg) {
                flex-grow: 1;
                margin-bottom: 10px;
            }
        }
        &-tags {
            font-size: 1.4rem;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 30px;
        }
        &-tag {
            padding: 5px 15px;
            background-color: $color-tag;
            border-radius: 30px;
            font-weight: bold;
        }
        &-button {
            display: block;
            width: fit-content;
            font-weight: bold;
            text-align: center;
            padding: 9px 39px;
            background-color: $color-blue;
            color: $color-white;
            border: 1px solid $color-blue;
            border-radius: 50px;
            position: relative;
            transition: background-color 0.5s, color 0.5s;

            & svg {
                position: absolute;
                right: 18px;
                top: 50%;
                transform: translateY(-50%);

                & ellipse {
                    transition: fill 0.5s;
                    
                }
                & path {
                    transition: fill 0.5s;
                }
            }
        }
    }
}
@use "../../Global" as *;

.p-header {
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @include mq(lg) {
        padding-left: 100px;
        padding-right: 110px;
    }
    &__logo {
        width: 180px;

        @include mq(lg) {
            width: auto;
        }
        &-link {
            display: flex;
            align-items: center;
            transition: color 0.5s ease;
            
            &:hover {
                color: $color-main;
            }
        }
    }
    &__menu {
        display: flex;
        align-items: center;
    }
    &__item {
        display: block;
        margin-left: 10px;

        @include mq(lg) {
            margin-left: 20px;
        }
    }
    &__link {
        font-size: 0.9rem;
        font-weight: bold;
        color: $color-white;
        width: 55px;
        height: 40px;
        border: 1px solid transparent;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color 0.5s, border 0.5s;

        @include mq(md) {
            font-size: 1.6rem;
            line-height: 1;
            letter-spacing: 0.08em;
            width: fit-content;
            height: auto;
            padding: 10px 20px;
            border: 2px solid transparent;
            border-radius: 30px;
        }
        @include mq(lg) {
            font-size: 1.9rem;
            padding: 13px 18px;
        }
        &.btn-green {
            
            @include mq(md) {
                gap: 10px;
            }
            &:hover {
                border: 1px solid $color-green2;
                background: $color-white;
                color: $color-green2;
                
                @include mq(md) {
                    border: 2px solid $color-green2;
                }
                & circle {
                    fill: $color-green2;
                }
                & path {
                    fill: $color-white;
                }
            }
        }
        &.btn-orange {

            @include mq(md) {
                gap: 20px;
            }
            &:hover {
                border: 1px solid $color-orange1;
                background: $color-white;
                color: $color-orange1;

                @include mq(md) {
                    border: 2px solid $color-orange1;
                }
                & circle {
                    fill: $color-orange1;
                }
                & path {
                    fill: $color-white;
                }
            }
        }
        & .l {
            font-size: 1rem;

            @include mq(md) {
                font-size: 1.6rem;
                line-height: 1;
            }
            @include mq(lg) {
                font-size: 1.9rem;
            }
        }
        & .sp-none {
            display: none;

            @include mq(md) {
                display: inline-block;
            }
        }
        & svg {
            display: none;

            @include mq(md) {
                display: block;
                margin-top: 2px;
            }
            & circle {
                transition: fill 0.5s;
            }
            & path {
                transition: fill 0.5s;
            }
        }
    }
}
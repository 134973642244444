@use "../../Global" as *;

.p-mv {
    padding-top: 30px;
    padding-bottom: 67px;
    background-image: url(../images/mv/mv_bg_sp.webp);
    background-size: cover;
    
    @include mq(lg) {
        padding-top: 72px;
        padding-bottom: 208px;
        // padding-bottom: 185px;
        background-image: url(../images/mv/mv_bg_pc.webp);
    }
    &__caption {
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        color: $color-darkblue;
        margin-bottom: 6px;
        // margin-bottom: 15px;
        
        @include mq(lg) {
            font-size: 3.36rem;
            letter-spacing: 0.05em;
            margin-bottom: 18px;

            & svg {
                width: 24px;
                transform: translateY(-5px);
            }
        }
        & .dot {
            display: inline-block;
            position: relative;

            &::before {
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $color-darkblue;
                position: absolute;
                top: -1px;
                left: 50%;
                transform: translateX(-50%);
                
                @include mq(lg) {
                    top: -3px;
                }
            }
        }
    }
    &__subtitle {
        font-size: 3.6rem;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.05em;
        color: $color-darkblue;
        margin-bottom: 15px;
        
        @include mq(lg) {
            margin-bottom: 6px;
        }
        & .s {
            font-size: 3.6rem;
        }
        & .marker {
            font-size: 4.8rem;
            background: linear-gradient(transparent 85%, $color-white 85%);
            background-position: left 0 bottom 8px;
            line-height: 1.25;
            
            @include mq(lg) {
                background-position: left 0 bottom 5px;
            }
        }
    }
    &__title {
        padding-top: 11px;
        padding-bottom: 30px;

        @include mq(lg) {
            font-size: 4.7rem;
            line-height: 1;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 15px;
        }
        & .c-split {

            &:nth-child(2) {

                & .c-title__point {
                    font-size: 4.8rem;
                }
            }
        }
    }
    &__media {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column-reverse;
        margin-top: -22px;
        z-index: 1;

        @include mq(lg) {
            max-width: 920px;
            padding-left: 0;
            padding-right: 0;
            margin-top: 0;
            margin-left: auto;
            margin-right: auto;
            flex-direction: row;
            position: relative;
        }
        & .column {
            padding-left: 30px;
            padding-right: 20px;
            display: grid;
            grid-template-columns: 7fr 3fr;
            gap: 10px;
        }
        &-point {
            margin-top: -53px;
            position: relative;
            z-index: 2;

            @include mq(lg) {
                margin-top: 0;
                width: 67%;
            }
        }
        &-image {

            @include mq(lg) {
                position: absolute;
                right: 5%;
                width: 33%;
                z-index: 1;
            }
        }
        &-icon {

            @include mq(lg) {
                width: 124px;
                height: 198px;
                position: absolute;
                right: -11px;
                bottom: -76px;
                z-index: 3;
            }
        }
    }
    &__cta {
        margin-top: -53px;
        width: calc(100% - 30px);
        position: relative;

        @include mq(lg) {
            margin-top: -178px;
        }
    }
}
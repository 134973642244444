@use "../../Global" as *;

.p-offer {
    display: block;
    padding-top: 506px;
    padding-bottom: 30px;
    background-image: url(../images/offer/offer_bg_sp.webp);
    background-size: cover;
    margin-top: -465px;
    margin-bottom: 54px;

    @include mq(lg) {
        padding-top: 272px;
        padding-bottom: 58px;
        background-image: url(../images/offer/offer_bg_pc.webp);
        margin-top: -222px;
        margin-bottom: 49px;
    }
    &__title {
        padding-top: 10px;
        margin-bottom: 12px;

        @include mq(lg) {
            margin-bottom: 22px;
        }
    }
    &__subtitle {
        font-size: 3.6rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 10px;

        @include mq(lg) {
            font-size: 4rem;
            margin-bottom: 10px;
        }
        & .l {
            font-size: 5rem;

            @include mq(lg) {
                font-size: 6rem;
            }
        }
    }
}
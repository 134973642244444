@use "../../Global" as *;

.p-download {
    display: block;
    padding-top: 20px;
    text-align: center;
    color: $color-white;
    border: 1px solid $color-orange1;
    border-radius: 20px;
    background-image: url(../images/download/download_bg_sp.png);
    background-size: cover;
    box-shadow: 0 5px 30px rgba(8, 29, 62, 0.16);
    overflow: hidden;

    @include mq(xl) {
        padding: 52px 0 28px 60px;
        background-image: url(../images/download/download_bg_pc.png);
        box-shadow: 0 15px 5px rgba(243, 216, 192, 1);
        border: none;
        position: relative;
        overflow: visible;
    }
    &:hover {
        box-shadow: none;
        transform: translate3d(0, 3px, 0);
        transition:shadow 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
        opacity: 0.7;

        & .p-download__button {
            background-color: $color-orange1;
            color: $color-white;
    
            & path {
                fill: $color-white;
    
                &.arrow {
                    fill: $color-orange1;
                    stroke: $color-orange1;
                }
            }
        }
    }
    &__inner {
        position: relative;

        @include mq(xl) {
    
            &::before {
                content: '';
                width: 490px;
                height: 329px;
                background-image: url(../images/download/image.webp);
                background-size: contain;
                position: absolute;
                bottom: -28px;
                left: 50%;

                @include mq(xl) {
                    left: 46%;
                }
            }
            &::after {
                content: '';
                width: 164px;
                height: 255px;
                background-image: url(../images/download/icon.webp);
                background-size: contain;
                position: absolute;
                bottom: -28px;
                right: 8px;
                z-index: 2;
            }
        }
    }
    &__body {

        @include mq(xl) {
            width: 50%;
        }
    }
    &__subtitle {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;

        @include mq(xl) {
            font-size: 3.2rem;
            text-align: left;
            margin-bottom: 5px;
        }
        & .l {
            font-family: $font-en;
            font-size: 3rem;

            @include mq(xl){
                font-size: 4.2rem;
            }
        }
    }
    &__title {
        font-size: 2rem;
        line-height: 1.05;
        letter-spacing: 0.05em;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 215px 65px;
        gap: 5px;
        margin-bottom: 11px;

        @include mq(xl) {
            font-size: 3.2rem;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 40px;
        }
        & .yellow {
            font-size: 4.8rem;
            line-height: 1.1;
            letter-spacing: 0.1em;

            @include mq(xl) {
                font-size: 6.6rem;
            }
        }
    }
    &__media {
        width: 204px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        @include mq(xl) {
            display: none;
        }
        &::after {
            content: '';
            width: 57px;
            height: 90px;
            background-image: url(../images/download/icon.webp);
            background-size: contain;
            position: absolute;
            right: -60px;
            bottom: -3px;
            z-index: 1;

            @include mq(xl) {
                display: none;
            }
        }
    }
    &__footer {
        padding: 10px 15px;
        background-color: $color-white;
        position: relative;
        z-index: 2;

        @include mq(xl) {
            background-color: transparent;
            padding: 0;
        }
    }
    &__button {
        display: block;
        width: 100%;
        height: 40px;
        border: 2px solid $color-orange1;
        line-height: 36px;
        font-size: 1.6rem;
        font-weight: bold;
        color: $color-orange1;
        border-radius: 50px;
        position: relative;
        transition: background-color 0.5s, color 0.5s;
        
        @include mq(xl) {
            width: 330px;
            height: 53px;
            line-height: 49px;
            font-size: 2.4rem;
            border: 2px solid $color-white;
            background-color: $color-white;
        }
        & svg {
            position: absolute;
            right: 15px;
            top: 52%;
            transform: translateY(-50%);
            transition: fill 0.5s, stroke 0.5s;
        }
    }
}
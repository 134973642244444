@use "../../Global" as *;

.p-risk {
    position: relative;
    z-index: 2;

    &__inner {
        
        padding-top: 27px;
        padding-bottom: 60px;
        background-image: url(../images/risk/risk_bg_sp.webp);
        background-size: cover;
        background-position: center;
    
        @include mq(lg) {
            padding-top: 50px;
            padding-bottom: 50px;
            background-image: url(../images/risk/risk_bg_pc.webp);
            border-radius: 30px;
        }
    }
    &__title {
        font-size: 4rem;
        color: $color-white;
        letter-spacing: 0.05em;
        margin-bottom: 15px;

        @include mq(lg) {
            margin-bottom: 30px;
        }
        & .back {
            padding-left: 10px;
            padding-right: 10px;
            margin-right: 10px;
            background-color: $color-risk;
            border-radius: 5px;
        }
        & .s {
            font-size: 3.2rem;
        }
    }
    &__grid {
        display: grid;
        gap: 20px;

        @include mq(lg) {
            display: flex;
            justify-content: center;
            gap: 30px;
        }
    }
    &__media, &__text {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }
    &__text {
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        padding-top: 5px;
        padding-bottom: 5px;
        color: $color-white;
        background-color: rgba($color-risk, 0.8);
        border-radius: 5px;
        margin-top: -23px;
        position: relative;
        z-index: 1;
    }
    &__message {
        font-size: 2.8rem;
        font-weight: bold;
        padding: 10px;
        white-space: nowrap;
        width: fit-content;
        background-color: $color-yellow;
        position: absolute;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%) rotate(-2deg);

        @include mq(lg) {
            padding: 6px;
        }
    }
}
@use "../../Global" as *;

.p-cta {
    max-width: 1080px;
    // padding-left: 20px;
    // padding-right: 20px;

    &__inner {
        padding: 26px 20px 40px;
        background-color: $color-white;
        box-shadow: 0 5px 30px rgba(8, 29, 62, 0.16);
        border-radius: 10px;
        
        @include mq(lg) {
            padding: 32px 70px 50px;
            // padding: 40px 70px 50px;
        }
    }
    &__title {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.45;
        letter-spacing: 0.05em;
        color: $color-darkblue;
        margin-bottom: 19px;

        @include mq(lg) {
            font-size: 2.4rem;
            margin-bottom: 21px;
            // margin-bottom: 32px;
        }
        & .marker {
            font-size: 3rem;
            background: linear-gradient(transparent 70%, $color-yellow 70%);
        }
    }
    &__buttons {
        display: grid;
        gap: 20px;

        @include mq(lg) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    &__button {
        width: 100%;
        height: 70px;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 0.1em;
        color: $color-white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px rgba(16, 39, 30, 0.22);
        position: relative;

        @include mq(lg) {
            height: 82px;
            font-size: 2rem;
            letter-spacing: 0.1em;
            border-radius: 20px;
        }
        @include mq(xl) {
            font-size: 2.4rem;
        }
        &::before {
            content: '';
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            border: 2px solid $color-white;
            border-radius: 10px;
            position: absolute;
            left: 5px;
            top: 5px;

            @include mq(lg) {
                border-radius: 20px;
            }
        }
        &.btn-green {
            position: relative;

            & .free {
                width: 27px;
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);

                @include mq(lg) {
                    width: 39px;
                }
                @include mq(xl) {
                    left: 30px;
                }
                & path {
                    transition: fill 0.5s;
                }
            }
            &:hover {
                background: $color-white;
                color: $color-green2;

                &::before {
                    border-color: $color-green2;
                }
                & .free {

                    & path {
                        fill: $color-white;

                        &.fill {
                            fill: $color-green2;
                        }
                    }
                }
                & .arrow {

                    & circle {
                        fill: $color-green2;
                    }
                    & path {
                        fill: $color-white;
                    }
                }
            }
        }
        &.btn-orange {

            @include mq(lg) {
                gap: 20px;

                & svg {
                    position: static;
                    transform: translateY(0);
                }
            }
            &:hover {
                background: $color-white;
                color: $color-orange1;

                &::before {
                    border-color: $color-orange1;
                }
                & svg {

                    & circle {
                        fill: $color-orange1;
                    }
                    & path {
                        fill: $color-white;
                    }
                }
            }
        }
        & svg.arrow {
            display: block;
            width: 16px;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            transition: fill 0.5s;

            @include mq(xl) {
                width: 24px;
                right: 30px;
            }
        }
    }
}
@use "../../Global" as *;

.p-use {
    display: block;
    padding-top: 30px;
    padding-bottom: 167px;
    background-color: $color-use;
    position: relative;
    
    @include mq(lg) {
        padding-top: 74px;
        padding-bottom: 213px;
    }
    &__title {
        color: $color-white;
        font-size: 2.2rem;

        @include mq(lg) {
            font-size: 3.2rem;
        }
        & .l {
            font-size: 3.2rem;
        }
        @include mq(lg) {

            & .pcl {
                font-size: 4rem;
            }
        }
        &::after {
            background-color: $color-white;
        }
    }
    &__list {
        display: grid;
        gap: 30px;

        @include mq(lg) {
            gap: 50px;
        }
    }
    &__item {

        @include mq(lg) {
            display: grid;
            grid-template-columns: 140px 1fr;
            align-items: center;
        }
        &-number {
            font-family: $font-en;
            font-size: 6.4rem;
            font-weight: bold;
            line-height: 0.9;
            text-align: center;
            color: rgba(255, 255, 255, 0.69);
            margin-bottom: 10px;

            @include mq(lg) {
                font-size: 8rem;
                margin-bottom: 0;
            }
        }
        &-content {
            padding: 30px 20px 40px;
            background-color: $color-white;
            border-radius: 20px;

            @include mq(lg) {
                height: 226px;
                padding: 40px;
                display: grid;
                align-items: center;
                grid-template-columns: 120px 1fr;
                gap: 20px;
            }
        }
        &-media {
            width: 120px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;

            @include mq(lg) {
                margin-bottom: 0;
            }
        }
        &-title {
            font-size: 2.8rem;
            letter-spacing: 0.03em;
            text-align: center;
            text-decoration: underline;
            text-underline-offset: 5px;
            color: $color-blue;
            margin-bottom: 20px;

            @include mq(lg) {
                text-align: left;
            }
        }
        &-caption {
            font-size: 1.8rem;
            line-height: 2;
            letter-spacing: 0.03em;

            @include mq(lg) {
                line-height: 1.5;
            }
            & br {
                display: none;

                @include mq(lg) {
                    display: block;
                }
            }
        }
    }
    &__cta {
        width: calc(100% - 30px);
        position: absolute;
        bottom: -200px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        @include mq(lg) {
            width: 100%;
            bottom: -116px;
        }
    }
}
@use "../Global" as *;

html {
    font-size: 10px;

    @include mq(sm) {
        font-size: 10px;
    }
    @include mq(md) {
        font-size: 10px;
    }
    @include mq(lg) {
        font-size: 10px;
    }
    @include mq(xl) {
        font-size: 10px;
    }
    @include mq(xxl) {
        font-size: 10px;
    }
}

body {
    font-family: $font-main;
    font-size: 1.4rem;
    line-height: 1.5;
    color: $color-black;
    background-color: #f7f7f7;

    @include mq(lg) {
        font-size: 1.6rem;
    }
    &.fixed {
        overflow: hidden;
    }
}

ul, ol {
    padding-left: 0;
}

li {
    list-style: none;
}

img, svg {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

img {
    width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

.montserrat {
    font-family: $font-en;
}

.btn-green {
    background: linear-gradient(90deg, rgba(32,213,29,1) 0%, rgba(4,195,0,1) 100%);
    transition: all 0.5s;
}
.btn-orange {
    background: linear-gradient(90deg, rgba(221,108,64,1) 0%, rgba(226,155,15,1) 100%);
    transition: all 0.5s;
}

.marker {
    background: linear-gradient(transparent 70%, $color-yellow 70%);
}

.blue {
    color: $color-blue;
}
.yellow {
    color: $color-yellow;
}
@use "../../Global" as *;

.p-about {
    display: block;
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 51px;

    @include mq(lg) {
        padding-top: 85px;
        // padding-top: 140px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 80px;
    }
    &__caption {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7.5px;
        margin-bottom: 14px;

        @include mq(lg) {
            margin-bottom: 12px;
        }
    }
    &__logo {
        margin-bottom: 20px;

        @include mq(lg) {
            margin-bottom: 15px;
        }
    }
    &__title {
        font-size: 2.2rem;
        line-height: 1.65;
        letter-spacing: 0.05em;
        margin-bottom: 17px;

        @include mq(lg) {
            font-size: 3.2rem;
            // margin-bottom: 28px;
        }
        & .blue {
            font-size: 3.6rem;
            text-decoration: underline;
            text-underline-offset: 5px;
        }
    }
    &__movie {
        max-width: 800px;
        position: relative;
        box-shadow: 0 5px 30px rgba(8, 29, 62, 0.16);

        &::before {
            content: '';
            display: block;
            padding-top: 56.25%;
        }
        & img {
            width: 99px;
            position: absolute;
            left: -12px;
            top: -66px;
            z-index: -1;

            @include mq(lg) {
                width: 170px;
                left: -77px;
                top: -106px;
            }
        }
        & iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}
@use "../../Global" as *;

.u-m {
    &x {
        &-auto {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &b {

        &xs {
            margin-bottom: 8px;
        }
        &s {
            margin-bottom: 16px;
        }
        &m {
            margin-bottom: 20px;
        }
        &l {
            margin-bottom: 48px;
        }
    }
}